import React, { useEffect, useState } from 'react';
import './index.css';
import RegisterOrRecover from '../RegisterOrRecover';
import WalletComponent from '../Wallet';
import { getLSWallet } from '../../lib/ls';
import { useLocalStorage } from "@uidotdev/usehooks";


function App() {
  const [wallet] = useLocalStorage("wallet", getLSWallet());

  return (
    <div className='app'>
      { wallet &&
        <WalletComponent/>
      }

      { !wallet &&
        <RegisterOrRecover/>
      }
    </div>
  );
}

export default App;
