import { Button, Typography, notification } from "antd";
import { useClipboard } from 'use-clipboard-copy'
import './index.css';

const { Text } = Typography;

type NotificationType = 'success' | 'info' | 'warning' | 'error';



function AddressLabel({ address }: { address: string }) {
	const clipboard = useClipboard();

	const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

	const onCopy=() => {
		try {
			clipboard.copy(address);
			openNotificationWithIcon('success', 'Success', 'The address was copied to clipboard');
		} catch (error) {
			openNotificationWithIcon('error', 'Error', `Something went wrong: ${error}`);
		}
	}

	return (
		<div className='address-label'>
			<Text copyable={{
				text: address,
				onCopy: onCopy,
				tooltips: ['Copy', null]
			}} ellipsis>{address}</Text>
		</div>
	);
}

export default AddressLabel;