import React, { useState } from 'react';
import { Button, Input, notification } from 'antd';
import './index.css';
import { recoverAccountFromPrivateKey, isMnemonic, recoverAccountFromSeedPhrase } from '../../lib/ethHelper';
import { setLSWallet } from '../../lib/ls';
import { WalletGeneral } from '../../interfaces/wallet';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

function RecoverComponent() {
    const [value, setValue] = useState('');

    const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
        notification[type]({
          message: message,
          description: description,
        });
    };

    const recover = () => {
        try {
            if (isMnemonic(value)) {
                const wallet = recoverAccountFromSeedPhrase(value);
                if (wallet) {
                    setLSWallet({
                        address: wallet?.address,
                        pk: wallet?.signingKey.privateKey
                    });
                }
            } else {
                const wallet = recoverAccountFromPrivateKey(value);
                if (wallet) {
                    setLSWallet({
                        address: wallet?.address,
                        pk: wallet?.signingKey.privateKey
                    });
                }
            }
        } catch (error) {
            openNotificationWithIcon('error', 'Error', `Recovery error: ${error}`);
        }
    }

    return (
        <div className='recover-container'>
            <Input className='recover-input' placeholder='Paste seed-phrase / private key' onChange={(e) => setValue(e.target.value)}/>
            <Button type="primary" onClick={recover}>Recover</Button>
        </div>
    );
}

export default RecoverComponent;