import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ReserveWallet } from "../interfaces/reserveWallet";
import { Routes } from "../enums/rotes.enum";
import { UUID } from "crypto";
import { WalletDto } from "../interfaces/wallet.dto";
import { v4 as uuidv4 } from 'uuid';

export function wallet() {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });


  async function reserveWallet(email?: string): Promise<ReserveWallet> {
    const config: AxiosRequestConfig = {
      method: 'post',
      url: Routes.WALLET_RESERVE,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },

      data: {
        // generate random UUID
        by: uuidv4(), 
        email: email
      }
    }
    
    const response = await axiosInstance.request(config);
    return response.data;
  }
  
  async function getReservedWallet(reservaionId: UUID): Promise<WalletDto> {
    console.log(process.env.REACT_APP_API_KEY)
    const config: AxiosRequestConfig = {
      method: 'get',
      url: Routes.GET_WALLET_RESERVE + reservaionId,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      }
    }
        
    const response = await axiosInstance.request(config);
    return response.data;
  }

  return {
    reserveWallet,
    getReservedWallet
  }

}