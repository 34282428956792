import { Wallet, JsonRpcProvider, Contract,  ethers } from 'ethers';
import { abi } from '../constants/abi/erc20';
// const ethers = require('ethers');

const web3Provider = () => {
  return new JsonRpcProvider(process.env.REACT_APP_BC_RPC_URL);
}

export const recoverAccountFromPrivateKey = (privateKey: string) => {
  const wallet = new Wallet(privateKey);
  return wallet;
};

export const recoverAccountFromSeedPhrase = (seedPhrase: string) => {
  const wallet = ethers.Wallet.fromPhrase(seedPhrase);
  return wallet;
};

export const isMnemonic = (value: string) => {
  return value.indexOf(' ') !== -1;
}

// get ERC20 balance by address with ethers
export const getBalanceByAddressNative = async (address: string) => {
  const provider = web3Provider();
  const balance = await provider.getBalance(address);
  return balance;
};


export const getERC20BalanceByAddress = async (address: string, contractAddress: string) => {
  const provider = web3Provider();
  const contract = new Contract(contractAddress, abi, provider);
  const balance = await contract.balanceOf(address);
  return balance;
};

export const formatDecimals = (number: string | bigint, decimals: number, precision? : number) => {
  console.log(number)
  const formatedBalance = ethers.formatUnits(number, decimals);
  if (precision) {
    return removeDecimalSymbols(formatedBalance.toString(), precision);
  }

  return formatedBalance;
}

export const unformatDecimals = (number: string, decimals: number) => {
  return ethers.parseUnits(number, decimals);
}

export const removeDecimalSymbols = (inputString: string, precision: number) => {
  const floatValue = parseFloat(inputString);
  
  if (!isNaN(floatValue)) {
    const roundedValue = floatValue.toFixed(precision);
    let stringValue = roundedValue.toString();
        
    return removeTrailingZeros(stringValue);
  } else {
    // Handle the case where inputString is not a valid number
    return '0';
  }
}

function removeTrailingZeros(inputString: string) {
  const floatValue = parseFloat(inputString);

  if (!isNaN(floatValue)) {
    // Convert the number to a string, removing trailing zeros and the decimal point
    const formattedValue = floatValue.toString().replace(/(\.[0-9]*?)0+$/, '$1');
    return formattedValue;
  } else {
    // Handle the case where inputString is not a valid number
    return 'Invalid input';
  }
}

export const transferERC20 = (contractAddress: string, to: string, amount: string | bigint, pk: string) => {
  const provider = web3Provider();
  const wallet = new ethers.Wallet(pk, provider);
  const contract = new Contract(contractAddress, abi, wallet);
  try {
    return contract.transfer(to, amount);
  } catch (error) {
    console.error('Error sending transaction:', error);
    return null;
  }
}

export const getDecimals = async (contractAddress: string) => {
  const provider = web3Provider();
  const contract = new Contract(contractAddress, abi, provider);
  const decimals = await contract.decimals();
  return decimals;
}