import React from 'react';
import './index.css';
import { Button, Input, notification } from 'antd';
import { useClipboard } from 'use-clipboard-copy'
import { ZERO_ADDRESS, USDT_ADDRESS } from '../../constants/constants';
import { getLSWallet, cleanLSWallet } from '../../lib/ls';
import { useLocalStorage } from '@uidotdev/usehooks';
import { transferERC20, unformatDecimals, getDecimals } from '../../lib/ethHelper';
import AddressLabel from '../AddressLabel';
import CurrenciesComponent from '../CurrenciesComponent';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

function WalletComponent() {
  const [wallet] = useLocalStorage("wallet", getLSWallet());
  const [receiverAddress, setReceiverAddress] = React.useState(ZERO_ADDRESS);
  const [transferAmount, setTransferAmount] = React.useState('0');
  const [preparingTransfer, setPreparingTransfer] = React.useState(false);
  const clipboard = useClipboard();

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  // TODO refactor. Split to components
  return (
    <div className="wallet-container">
      <Button className='sign-out' onClick={cleanLSWallet} style={{ margin: '20px' }}>Sign out</Button>
      <AddressLabel address={wallet.address}/>

      <CurrenciesComponent depositAddress={wallet.address}/>
      
      <div className='flex-container' style={ { width: '70%', maxWidth: '500px' }}>
        <Input placeholder='Send to address' onChange={(e) => setReceiverAddress(e.target.value)}/>
        <Input placeholder='Amount' onChange={(e) => setTransferAmount(e.target.value)}/>
        <Button type="primary" onClick={
          async () => {
            setPreparingTransfer(true);
            try {
              const tx = await transferERC20(
                USDT_ADDRESS,
                receiverAddress,
                unformatDecimals(transferAmount, await getDecimals(USDT_ADDRESS)),
                wallet.pk
              );
              openNotificationWithIcon('success', 'Success', `The transaction was sent. ${tx.hash} `);
              setPreparingTransfer(false);

              tx.wait().then((receipt: any) => {
                console.log(receipt);
                openNotificationWithIcon('success', 'Success', `The transaction was mined in block ${receipt.blockNumber} `);
              })
            } catch (error) {
              openNotificationWithIcon('error', 'Error', `Something went wrong: ${error}`);
            } finally {
              setPreparingTransfer(false);
            }
          }}
          loading={preparingTransfer}>
          Send USDT
        </Button>
      </div>

      <Button
        style={{ height: '50px', margin: '20px'}}
        onClick={() => {
          try {
            clipboard.copy(wallet.pk)
            openNotificationWithIcon('success', 'Success', 'The private key was copied to clipboard');
          } catch (error) {
            openNotificationWithIcon('error', 'Error', `Something went wrong: ${error}`);
          }
        }}
      >Backup my wallet</Button>
      
    </div>
  );
}

export default WalletComponent;
