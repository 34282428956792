export const enum CurrencyType { NATIVE, ERC20 }

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const USDT_ADDRESS = process.env.REACT_APP_USDT_ADDRESS as string;
export const NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME as string;
export const DEFAULT_DEPOSIT_AMOUNT = process.env.REACT_APP_DEFAULT_DEPOSIT_AMOUNT as string;
export const DEFAULT_DEPOSIT_ASSET = process.env.REACT_APP_DEFAULT_DEPOSIT_ASSET as string;

export const maintainingCurrencies = [
  {
    name: "USDT",
    symbol: "USDT",
    address: USDT_ADDRESS,
    decimals: Number(process.env.REACT_APP_USDT_DECIMALS),
    type: CurrencyType.ERC20
  },
  {
    name: "BNB",
    symbol: "BNB",
    address: "0x0000000000000000000000000000000000000000",
    decimals: 18,
    balance: CurrencyType.NATIVE
  },
];



