import React from 'react';
import './index.css';
import { formatDecimals, getBalanceByAddressNative, getERC20BalanceByAddress } from '../../lib/ethHelper';
import { useLocalStorage } from '@uidotdev/usehooks';
import { getLSWallet } from '../../lib/ls';
import { CurrencyType } from '../../constants/constants';

function CurrencyElement({ currency }: any) {
  const [balance, setBalance] = React.useState('0');
  const [wallet] = useLocalStorage("wallet", getLSWallet());

  
  const loadBalance = async () => {
    const balance = currency.type === CurrencyType.ERC20 ? await getERC20BalanceByAddress(wallet.address, currency.address) : await getBalanceByAddressNative(wallet.address);
    console.log(balance);
    setBalance(formatDecimals(balance, currency.decimals, 2));
  }
  React.useEffect(() => {
    loadBalance();
  }, [])

  return (
    <div className="currency">
      <p>{currency.symbol}</p>
      <p>{balance}</p>
    </div>
  );
}

export default CurrencyElement;
