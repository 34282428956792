import { Button } from "antd";
import './index.css';
import CurrencyElement from "../CurrencyElement";
import {
  DEFAULT_DEPOSIT_AMOUNT,
  DEFAULT_DEPOSIT_ASSET,
  maintainingCurrencies,
  NETWORK_NAME
} from "../../constants/constants";

function CurrenciesComponent({depositAddress}: {depositAddress: string}) {
  return (
    <div className='flex-container currencies-container'>
      {maintainingCurrencies.map((currency, index) => (
        CurrencyElement({ currency, index })
      ))}
      <Button href={
        process.env.REACT_APP_EXTERNAL_DEPOSIT_URL
        ?.replace('{net}', NETWORK_NAME)
        .replace('{address}', depositAddress)
        .replace('{amount}', DEFAULT_DEPOSIT_AMOUNT)
        .replace('{asset}', DEFAULT_DEPOSIT_ASSET)
        } target={'_blank'} type="primary">Buy USDT</Button>
    </div>
  )
}

export default CurrenciesComponent;