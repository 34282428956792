import RegisterComponent from '../Register';
import RecoverComponent from '../Recover';
import { Typography, Divider } from 'antd';
import './index.css';

function RegisterOrRecover() {
  return (
    <>
      <div className="register-or-recover">
        <RegisterComponent/>
        <Divider plain style={{ margin: '0' }}>
          <Typography.Title level={2} style={{ margin: 0, textAlign: 'center', color: 'gray' }}>
            OR
          </Typography.Title>
        </Divider>
        
        <RecoverComponent/>
      </div>
    </>
  );
}

export default RegisterOrRecover;
