import { ReserveWallet } from "../interfaces/reserveWallet";
import { WalletGeneral } from "../interfaces/wallet";

export const getLSWallet = () => {
  const wallet = localStorage.getItem(walletLSKey);
  if (wallet) {
    return JSON.parse(wallet);
  }
}

export const setLSWallet = (wallet: WalletGeneral) => {
  localStorage.setItem(walletLSKey, JSON.stringify(wallet));
  window.dispatchEvent(
    new StorageEvent("storage", { key: walletLSKey, newValue: getLSWallet() })
   );
}

export const cleanLSWallet = () => {
  localStorage.removeItem(walletLSKey);
  window.dispatchEvent(
    new StorageEvent("storage", { key: walletLSKey, newValue: getLSWallet() })
   );
}

export const walletLSKey = 'wallet';