import { useState } from 'react';
import { Button, Checkbox, Input, notification } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import './index.css';

import { wallet } from '../../lib/wallet';
import { setLSWallet } from '../../lib/ls';
const { reserveWallet, getReservedWallet } = wallet();

type NotificationType = 'success' | 'info' | 'warning' | 'error';

function RegisterComponent() {
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState('');

  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const register = async () => {
    try {
      const reservation = await reserveWallet(email && checked ? email : undefined);
      const wallet = await getReservedWallet(reservation.id);
      setLSWallet(wallet)
    } catch (error) {
      openNotificationWithIcon('error', 'Error', `Registration error: ${error}`);
    }
  }
  
  return (
    <div className='register-container'>
      <Button type="primary" onClick={register}>Register</Button>
      <Checkbox onChange={onChange} className='recovery-checkbox'>Recovery via email</Checkbox>
      <Input placeholder='example@gmail.com' disabled={!checked} className='email-input' onChange={(e) => setEmail(e.target.value)}/>
    </div>
  );
}

export default RegisterComponent;
